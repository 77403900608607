// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  batchSdnCalls: process.env['NX_PUBLIC_BATCH_SDN_CALLS'] !== '0',
  appVersion: process.env['NX_PUBLIC_APP_VERSION'],
  appEnv: process.env['NX_PUBLIC_APP_ENV'],
  powerTabsSfID: 'a56Dm000000XxtPIAS',
  panelDataSfID: 'a56VE000000EEMTYA4',
  panelDataIframeUrl: 'https://spinsllc.auth0.com/samlp/sT8Wtkhc67OUzaSvrzuHGFtHaS1fXqQH/',
  spinsLlcId: '0013p000029NprlAAC',
  remoteAdminUrl: 'https://admin-dot-spins-insights-portal-dev.uc.r.appspot.com/',
  remoteRetailInsightsUrl: 'https://dev.retail-platform.spins.team',
  remoteBrandInsightsUrl: 'https://dev.brand-insights.spins.team',
  remotePartnerInsightsUrl: 'https://spins-partner-insights-dev.uc.r.appspot.com/',
  remotePowerTabsUrl: 'https://dev.powertabs.spins.team/',
  spinsDigestUrl: 'https://dev.digest-ui.spins.team',
  onMaintenance: false,
  allowedCompanyTypesDefaultFilters: ['RETAILER'],
};
